var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "usage-by pr-4" }, [
    _c("div", { staticClass: "usage-by--plan mb-4" }, [
      _vm._v(_vm._s(_vm.$t(`plans.${_vm.plan}`))),
    ]),
    _c("div", [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("streaming")))]),
        _c("span", [_vm._v(_vm._s(_vm.$t("hoursLeft", { hour: 12 })))]),
      ]),
      _c(
        "div",
        { staticClass: "usage-by--progress" },
        [
          _c("v-progress-linear", {
            attrs: { value: "80", height: "10", color: "darkgreen" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex usage-by--sm-text" }, [
        _c("span", { staticClass: "mr-4" }, [
          _vm._v(_vm._s(_vm.$t("recording")) + ":"),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.$t("hoursStream", { hour: 6 })))]),
      ]),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between mt-4 align-center" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("moreStream")))]),
          _c("v-btn", { attrs: { to: "upgdare", text: "", small: "" } }, [
            _vm._v(" " + _vm._s(_vm.$t("upgrade")) + " "),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }