<template>
  <div class="d-flex align-center justify-space-between dashboard">
    <div class="dashboard-image-bar left-images d-flex flex-column justify-space-between mx-2">
      <div class="first d-flex align-center px-2 cursor-pointer justify-center" @click="goToWebsite">
        <span class="text-center">{{ $t('dashboard.informations.leftFirst') }}</span>
      </div>
      <div class="second d-flex align-center px-2 cursor-pointer justify-center" @click="goToWebsite">
        <span class="text-center">{{ $t('dashboard.informations.leftSecond') }}</span>
      </div>
    </div>
    <v-container class="mx-0">
      <TopBar />
      <v-row class="dashboard--body" :class="{ mobile: $isMobile }">
        <v-col class="dashboard--body--navigation" xl="3" lg="3" md="4" cols="12">
          <div class="d-flex justify-space-between flex-column dashboard--body--navigation--list">
            <v-list data-test-id="dashboard-list">
              <div v-for="(item, index) in navigationItems" :key="item.title">
                <v-divider v-if="index !== 0"></v-divider>
                <v-list-item :data-test-id="`dashboard-${item.path}`" :to="`/dashboard/${item.path}`" link :ripple="false">
                  <v-list-item-icon>
                    <AppIcon :icon="item.icon" size="50" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t(item.subTitle) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <div v-show="false" class="dashboard--body--navigation--usage-by">
              <UsageBy />
            </div>
          </div>
        </v-col>
        <v-col xl="9" lg="9" md="8" cols="12">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
    <div class="dashboard-image-bar right-images d-flex flex-column justify-space-between mx-2">
      <div class="first d-flex align-center px-2 cursor-pointer justify-center" @click="goToWebsite">
        <span class="text-center">{{ $t('dashboard.informations.rightFirst') }}</span>
      </div>
      <div class="second d-flex align-center px-2 cursor-pointer justify-center" @click="goToWebsite">
        <span class="text-center">{{ $t('dashboard.informations.rightSecond') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import UsageBy from '@/components/UsageBy.vue';
import TopBar from '@/components/TopBar.vue';
import { createATagAndClick } from '@/helpers/create-element';

export default {
  name: 'Dashboard',
  components: { UsageBy, TopBar },
  data() {
    return {
      drawer: true,
      navigationItems: [
        { title: 'dashboard.pages.broadcasts.title', subTitle: 'dashboard.pages.broadcasts.subTitle', path: 'broadcasts', icon: 'burger' },
        { title: 'dashboard.pages.destinations.title', subTitle: 'dashboard.pages.destinations.subTitle', path: 'destinations', icon: 'crab' },
        { title: 'dashboard.pages.videos.title', subTitle: 'dashboard.pages.videos.subTitle', path: 'videos', icon: 'icecream' },
      ],
    };
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters('User', ['getFullName']),
    usageStorageText() {
      return `1gb of 5gb used`;
    },
    usagePercentage() {
      return 20;
    },
  },

  methods: {
    ...mapActions('User', ['logout']),
    goToWebsite() {
      createATagAndClick('https://xperstream.com', '_blank');
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  height: 100%;

  .dashboard-image-bar {
    @media (max-width: $lg-breakpoint) {
      display: none !important;
    }

    width: 20%;
    min-width: 15%;
    height: 100%;
    &.left-images,
    &.right-images {
      .first,
      .second {
        height: 49%;
        background-position: center;
        background-size: cover;
        font-size: 25px;
        line-height: 37.5px;
        font-weight: 400;
      }
    }
    &.left-images {
      .first {
        background-image: url('/images/stream-background-1.svg');
      }
      .second {
        background-image: url('/images/stream-background-3.svg');
      }
    }
    &.right-images {
      .first {
        background-image: url('/images/stream-background-2.svg');
      }
      .second {
        background-image: url('/images/stream-background-4.svg');
      }
    }
  }

  .container {
    height: 100%;
    max-width: 1200px;
  }

  &--body {
    height: calc(100vh - 85px);
    &.mobile {
      height: auto;
    }
    &--navigation {
      position: relative;
      &--list {
        .v-list {
          border-radius: 8px;
        }
        height: 100%;
      }
    }
  }
}
</style>

