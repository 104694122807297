<template>
  <div class="usage-by pr-4">
    <div class="usage-by--plan mb-4">{{ $t(`plans.${plan}`) }}</div>

    <div>
      <div class="d-flex justify-space-between">
        <span>{{ $t('streaming') }}</span>
        <span>{{ $t('hoursLeft', { hour: 12 }) }}</span>
      </div>
      <div class="usage-by--progress">
        <v-progress-linear value="80" height="10" color="darkgreen"></v-progress-linear>
      </div>
      <div class="d-flex usage-by--sm-text">
        <span class="mr-4">{{ $t('recording') }}:</span>
        <span>{{ $t('hoursStream', { hour: 6 }) }}</span>
      </div>
      <div class="d-flex justify-space-between mt-4 align-center">
        <span>{{ $t('moreStream') }}</span>
        <v-btn to="upgdare" text small>
          {{ $t('upgrade') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsageBy',
  props: {
    plan: {
      type: String,
      default: 'pro',
    },
  },
};
</script>

<style lang="scss">
.usage-by {
  font-size: 13px;
  color: white;
  opacity: 0.8;

  &--plan {
    color: white;
    opacity: 0.8;
  }

  &--progress {
    background-color: var(--v-dark-gray-2-base);
    padding: 2px;
  }

  &--sm-text {
    font-size: 11px;
    color: white;
    opacity: 0.5;
  }
}
</style>
