var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center justify-space-between dashboard" },
    [
      _c(
        "div",
        {
          staticClass:
            "dashboard-image-bar left-images d-flex flex-column justify-space-between mx-2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "first d-flex align-center px-2 cursor-pointer justify-center",
              on: { click: _vm.goToWebsite },
            },
            [
              _c("span", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.informations.leftFirst"))),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "second d-flex align-center px-2 cursor-pointer justify-center",
              on: { click: _vm.goToWebsite },
            },
            [
              _c("span", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.informations.leftSecond"))),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "v-container",
        { staticClass: "mx-0" },
        [
          _c("TopBar"),
          _c(
            "v-row",
            {
              staticClass: "dashboard--body",
              class: { mobile: _vm.$isMobile },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "dashboard--body--navigation",
                  attrs: { xl: "3", lg: "3", md: "4", cols: "12" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between flex-column dashboard--body--navigation--list",
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { "data-test-id": "dashboard-list" } },
                        _vm._l(_vm.navigationItems, function (item, index) {
                          return _c(
                            "div",
                            { key: item.title },
                            [
                              index !== 0 ? _c("v-divider") : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    "data-test-id": `dashboard-${item.path}`,
                                    to: `/dashboard/${item.path}`,
                                    link: "",
                                    ripple: false,
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("AppIcon", {
                                        attrs: { icon: item.icon, size: "50" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$t(item.title))),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(_vm.$t(item.subTitle))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "dashboard--body--navigation--usage-by",
                        },
                        [_c("UsageBy")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { xl: "9", lg: "9", md: "8", cols: "12" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "dashboard-image-bar right-images d-flex flex-column justify-space-between mx-2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "first d-flex align-center px-2 cursor-pointer justify-center",
              on: { click: _vm.goToWebsite },
            },
            [
              _c("span", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.informations.rightFirst"))),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "second d-flex align-center px-2 cursor-pointer justify-center",
              on: { click: _vm.goToWebsite },
            },
            [
              _c("span", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.informations.rightSecond"))),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }